@import url("https://fonts.cdnfonts.com/css/kozuka-gothic-pro");

html {
  font-family: "Kozuka Gothic Pro", sans-serif;
}
body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.app-btn {
  border-radius: 8px;
  min-width: 167px;
  min-height: 48px;
  background-color: #031759;
}
.app-btn:hover {
  /* background-color: #f1634d; */
}
.app-container {
  max-width: 1440px;
  margin: auto;
}
.about-image {
  /* background-image: url('./assets/engineer-4922420_1920.jpeg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mission-image {
  /* background-image: url('./assets/cog-wheels-2125169_1920.jpeg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.vision-image {
  /* background-image: url('./assets/metaverse-7081849_1280.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.techdistbg {
  background-image: url("./assets/techdistbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.finbg {
  background-image: url("./assets/finbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ccbg {
  background-image: url("./assets/ccbg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.orangebg {
  background-image: url("./assets/orangebg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mssbg {
  background-image: linear-gradient(
      to bottom,
      rgba(241, 99, 77, 0.75),
      rgba(241, 99, 77, 0.75)
    ),
    url("./assets/ccbg.png");
  clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 35%,
    1% 34.97%,
    2% 34.9%,
    3% 34.77%,
    4% 34.59%,
    5% 34.37%,
    6% 34.1%,
    7% 33.78%,
    8% 33.41%,
    9% 33%,
    10% 32.56%,
    11% 32.07%,
    12% 31.55%,
    13% 30.99%,
    14% 30.4%,
    15% 29.79%,
    16% 29.15%,
    17% 28.49%,
    18% 27.81%,
    19% 27.12%,
    20% 26.42%,
    21% 25.71%,
    22% 24.99%,
    23% 24.28%,
    24% 23.57%,
    25% 22.87%,
    26% 22.18%,
    27% 21.5%,
    28% 20.84%,
    29% 20.2%,
    30% 19.59%,
    31% 19%,
    32% 18.44%,
    33% 17.92%,
    34% 17.44%,
    35% 16.99%,
    36% 16.58%,
    37% 16.22%,
    38% 15.9%,
    39% 15.63%,
    40% 15.4%,
    41% 15.23%,
    42% 15.1%,
    43% 15.02%,
    44% 15%,
    45% 15.03%,
    46% 15.1%,
    47% 15.23%,
    48% 15.41%,
    49% 15.64%,
    50% 15.91%,
    51% 16.23%,
    52% 16.6%,
    53% 17%,
    54% 17.45%,
    55% 17.94%,
    56% 18.46%,
    57% 19.02%,
    58% 19.61%,
    59% 20.22%,
    60% 20.86%,
    61% 21.52%,
    62% 22.2%,
    63% 22.89%,
    64% 23.6%,
    65% 24.31%,
    66% 25.02%,
    67% 25.73%,
    68% 26.44%,
    69% 27.15%,
    70% 27.84%,
    71% 28.51%,
    72% 29.17%,
    73% 29.81%,
    74% 30.42%,
    75% 31.01%,
    76% 31.57%,
    77% 32.09%,
    78% 32.57%,
    79% 33.02%,
    80% 33.42%,
    81% 33.79%,
    82% 34.11%,
    83% 34.38%,
    84% 34.6%,
    85% 34.78%,
    86% 34.9%,
    87% 34.98%,
    88% 35%,
    89% 34.97%,
    90% 34.89%,
    91% 34.77%,
    92% 34.59%,
    93% 34.36%,
    94% 34.09%,
    95% 33.76%,
    96% 33.4%,
    97% 32.99%,
    98% 32.54%,
    99% 32.05%,
    100% 31.53%
  );
}
.add-app-red::before {
  content: "";
  width: 200px;
  height: 15px;
  background-color: #f1634d;
  position: absolute;
  transform: translateY(-20px);
  border-radius: 8px;
  color: #0000ff0d;
  /* opacity: .90; */
}
@media screen and (max-width: 1280px) {
  .add-app-red::before {
    content: "";
    width: 200px;
    height: 15px;
    background-color: #f1634d;
    position: absolute;
    transform: translate(-50%, -15px);
    border-radius: 8px;
    left: 50%;
    display: none;
    /* opacity: .90; */
  }
  .finbg {
    background-image: none;
  }
}
.add-app-red-to-nav:focus {
  color: #f1634d !important;

  /* transform: translateX(-50%); */
}
.app-light-red {
  background-color: #f1634d;
}
.add-app-red-to-nav:hover::before {
  content: "";
  width: 70%;
  height: 5px;
  background-color: #f1634d !important;
  position: absolute;
  border-radius: 8px;
  left: 8px;
  bottom: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-common-bg {
  background: linear-gradient(
      rgba(255, 255, 255, 0.544),
      rgba(255, 255, 255, 0.5)
    ),
    url("./assets/IconsBG.png");
  background-repeat: no-repeat;
  background-attachment: fixed;

  background-size: cover;
}
.app-common-bg-not-fixed {
  background: linear-gradient(
      rgba(255, 255, 255, 0.544),
      rgba(255, 255, 255, 0.5)
    ),
    url("./assets/IconsBG.png");
  background-repeat: no-repeat;

  background-size: cover;
}
.techdisttext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: center;

  color: #ffffff;
}
.styledListItem {
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
  /* font-family: 'Kozuka Gothic Pro'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;

  color: #474747;
}
.styledListItem::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f1634d;
  position: absolute;
  left: -20px;
  border-radius: 20px;
}
.detailbox p {
  /* font-family: 'Kozuka Gothic Pro'; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: center;

  color: #ffffff;
}
@media screen and (max-width: 1280px) {
  .ccbg,
  .mssbg {
    clip-path: none;
  }
}
@media screen and (max-width: 900px) {
  .mssbg {
    clip-path: none;
  }
  .techdisttext,
  .detailbox p {
    /* font-family: 'Kozuka Gothic Pro'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;

    color: #ffffff;
  }
}
.p98638 {
  background-color: rgba(242, 104, 54, 0.99);
}
.p98638:hover {
  background-color: rgba(255, 255, 255, 0.99);
}
.p98638 a {
  color: #ffffff;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 48px;
}
.p98638:hover a {
  text-decoration: underline;
  color: #0000ff;
}
.p98638 h1,
.p98638 p {
  color: #ffffff;
}
.p98638:hover p {
  color: #888888;
}
.p98638:hover span {
  color: #888888;
}
.p98638:hover h1 {
  color: #f26836;
}
.hfgjhkj {
  background: linear-gradient(rgba(31, 12, 171, 0.544), rgba(69, 14, 250, 0.5)),
    url("./assets/Group\ 79.png");
  background-repeat: no-repeat;

  background-size: cover;
}
.imgbg {
  background: url("./assets/imgbg.gif");
  background-repeat: no-repeat;

  background-size: cover;
}
.profile-card .pink-section {
  background-color: #f26836;
}
.profile-card .dark-section {
  background-color: #3e3e3e;
  color: #ffffff;
}
.profile-card:hover .pink-section {
  background-color: #3e3e3e;
  transition: background 1s;
}
.profile-card:hover .dark-section {
  background-color: #fff;
  color: black;
  transition: background 1s;
}
.profile-card:hover .dark-section a {
  color: blue;
  text-decoration: underline;
}
.team-button:hover {
  background: white;
}
.team-button:hover span {
  color: black;
}
.team-button:hover .team-button-icon {
  display: block;
}
